<div class="section" id="projects">
  <div class="container cc-education">
    <div class="h4 text-center mb-4 title">Projects</div>

    <div class="row">


      <div class="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="blog-entry">
          <div class="card">
            <img class="card-img-top" src="assets/images/eid.jpg" alt="Card image" style="width:100%">
            <div class="card-body">
              <h4 class="card-title">Eid Card Reader to JSON</h4>
              <h5 class="card-text"><b>Technology : </b>.NET/C#</h5>

              <a href="https://github.com/yacsnipe/EidCardData-2-JSON" class="btn btn-primary stretched-link">Github Link</a>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
        <div class="blog-entry">
          <div class="card">
            <img class="card-img-top" src="assets/images/python.jpg" alt="Card image" style="width:100%">
            <div class="card-body">
              <h4 class="card-title">Logs filter </h4>
              <h5 class="card-text"><b>Technology : </b>Python</h5>

              <a href="https://github.com/yacsnipe/IP_LogsFilter" class="btn btn-primary stretched-link">Github Link</a>

            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</div>
