<div class="section" id="contact">
        <div class="cc-contact-information" s yle="background-image: url('');">
          <div class="container">
            <div class="cc-contact">
              <div class="row">
                <div class="col-md-9">
                  <div class="card mb-0" data-aos="zoom-in">
                    <div class="h4 text-center title">Contact Me</div>
                    <div class="row">                     
                      <div class="col-md-6">
                        <div class="card-body">
                          <p class="mb-0">
                            <strong>Phone</strong>
                          </p>
                          <p class="pb-2">+32 485313005</p>
                          <p class="mb-0">
                            <strong>Email</strong>
                          </p>
                          <p>yacine.elyoussoufi@gmail.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-snotify class="material"></ng-snotify>
