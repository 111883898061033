<div class="section" id="about">
    <div class="container">
      <div class="card" data-aos="fade-up" data-aos-offset="10">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="card-body">
              <div class="h4 mt-0 title">About</div>
              <p>Hi I'm <strong>Yacine El Youssoufi</strong>. Currently I am Web Developer and also have expertise with Angular 8  and C#.</p>


              <p>
                Lover of innovation and everything related to generate new knowledge.Face problems with a smile and solve them as soon as possible. Very calculated about the time I spend and work I do.
              </p> 
                
          
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="card-body">
              <div class="h4 mt-0 title">Basic Information</div>
              <div class="row">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Date of Birth:</strong>
                </div>
                <div class="col-sm-8">Augustus 8, 1989</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Email:</strong>
                </div>
                <div class="col-sm-8">yacine.elyoussoufi@gmail.com</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Phone:</strong>
                </div>
                <div class="col-sm-8">+32 485/31.30.05</div>
              </div>
             
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Language:</strong>
                </div>
                <div class="col-sm-8">French, English</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
