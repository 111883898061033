<footer class="footer">
    <div class="container text-center">
      <a class="cc-facebook btn btn-link" href="https://www.linkedin.com/in/yacine-el-youssoufi-3a202887" target="_blank">
        <i class="fa fa-linkedin fa-2x " aria-hidden="true"></i>
      </a>
   
      <a class="cc-github btn btn-link " href="https://github.com/yacsnipe" target="_blank">
        <i class="fa fa-github fa-2x " aria-hidden="true"></i>
      </a>
 
    </div>
    <div class="text-center text-muted">
      <p>&copy; All rights reserved.
      </p>
    </div>
  </footer>
