<div class="section" id="education">
    <div class="container cc-education">
      <div class="h4 text-center mb-4 title">Education</div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2009 - 2013</p>
              <div class="h5">Bachelor's Degree</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">
                Bachelor in Computer Science and System
              </div>
               <h4 class="category">HEB - école supérieure d'informatique (ESI)
               </h4>
           
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
