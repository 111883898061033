<div class="section" id="experience">
  <div class="container cc-education">
    <div class="h4 text-center mb-4 title">Work Experience</div>
    <div class="colorlib-narrow-content">

      <div class="row">
        <div class="col-md-12">
          <div class="timeline-centered">

            <article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-1">
                  <i class="icon-pen2"></i>
                </div>
                <div class="timeline-label">
                  <h2>Full Stack developer ASP.NET and angular 8<span> Augustus, 2018 - Now</span></h2>
                  <h4>Service Public Francophone Bruxellois</h4>
                  <p>
                    Creation of a training evaluation software for the human resources department.
                    This application communicates via WEB API REST and WCF/SOAP web
                    services with an angular 8 front-end.
                    Technologies and Language used:
                  </p><ul>
                    <li>C#/ASP.NET</li>
                    <li>Angular 8</li>
                    <li>Entity Framework</li>
                    <li>LINQ</li>
                    <li>WEB API REST</li>
                    <li>WCF</li>
                    <li>GIT</li>
                    <li>SignalR</li>
                    <li>Angular Materials</li>
                    <li>MongoDB</li>

                  </ul>

                </div>
              </div>
            </article>

            <article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-1">
                  <i class="icon-pen2"></i>
                </div>
                <div class="timeline-label">
                  <h2>Full Stack developer ASP.NET and angular 7<span> April, 2018 - Now</span></h2>
                  <h4>Forest Medical Center</h4>
                  <p>
                    Start from scratch Angular application with ASP. NET MVC 6 which makes it
                    possible to enroll patients in the medical center with the identity card. The
                    program uses a modified Belgian Electronic card id Middleware.
                    Technologies and Language used:
                  </p><ul>
                    <li>C#/ASP.NET</li>
                    <li>Angular 7</li>
                    <li>Entity Framework</li>
                    <li>LINQ</li>
                    <li>WEB API REST</li>
                    <li>GIT</li>
                    <li>Angular Materials</li>
                    <li>MongoDB</li>
                    <li>FireBase</li>
                  </ul>

                </div>
              </div>
            </article>

            <article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-1">
                  <i class="icon-pen2"></i>
                </div>
                <div class="timeline-label">
                  <h2>Full Stack developer ASP.NET Angular 6 <span> October, 2016 - April, 2018</span></h2>
                  <h4>Federal Computer Crime Unit</h4>
                  <p>
                    Start from scratch an Angular application which handle cops folders from the
                    Federal section.
                    Technologies and Language used:
                  </p><ul>
                    <li>C#/ASP.NET</li>
                    <li>Angular 6</li>
                    <li>Entity Framework</li>
                    <li>LINQ</li>
                    <li>REST</li>
                    <li>WCF</li>
                    <li>TFS</li>
                    <li>SQL Server</li>
                  </ul>

                </div>
              </div>
            </article>


            <article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-1">
                  <i class="icon-pen2"></i>
                </div>
                <div class="timeline-label">
                  <h2>Full Stack developer ASP.NET MVC 5 <span> February, 2014 - October, 2016</span></h2>
                  <h4>Forest Medical Center</h4>
                  <p>
                    Start from scratch an ASP.NET application which scan documents and store it
                    by patient.
                    Technologies and Language used:
                  </p><ul>
                    <li>C#</li>
                    <li>ASP.NET MVC5</li>
                    <li>Entity Framework</li>
                    <li>LINQ</li>
                    <li>REST</li>
                    <li>JSON</li>
                    <li>OCR</li>
                  </ul>

                </div>
              </div>
            </article>




            <article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-1">
                  <i class="icon-pen2"></i>
                </div>
                <div class="timeline-label">
                  <h2>Full Stack developer ASP.NET MVC 4 <span>May, 2013 - September,2013</span></h2>
                  <h4>Université Libre de Bruxelles (2013)</h4>
                  <p>
                    Completed Internship on ASP .NET . Worked as an Intern to develop web application using Visual Studio.
                    Technologies and Language used:
                  </p><ul>
                    <li>C#</li>
                    <li>ASP.NET MVC4</li>
                    <li>Entity Framework</li>
                    <li>LINQ</li>
                    <li>REST</li>
                    <li>JSON</li>
                    <li>XML</li>
                    <li>Oracle DB</li>
                  </ul>

                </div>
              </div>
            </article>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
