/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./education.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./education.component";
var styles_EducationComponent = [i0.styles];
var RenderType_EducationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EducationComponent, data: {} });
export { RenderType_EducationComponent as RenderType_EducationComponent };
export function View_EducationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "section"], ["id", "education"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "container cc-education"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "h4 text-center mb-4 title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Education"])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "col-md-3 bg-primary"], ["data-aos", "fade-right"], ["data-aos-duration", "500"], ["data-aos-offset", "50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "card-body cc-education-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2009 - 2013"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bachelor's Degree"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "col-md-9"], ["data-aos", "fade-left"], ["data-aos-duration", "500"], ["data-aos-offset", "50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Bachelor in Computer Science and System "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "h4", [["class", "category"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["HEB - \u00E9cole sup\u00E9rieure d'informatique (ESI) "]))], null, null); }
export function View_EducationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-education", [], null, null, null, View_EducationComponent_0, RenderType_EducationComponent)), i1.ɵdid(1, 114688, null, 0, i2.EducationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EducationComponentNgFactory = i1.ɵccf("app-education", i2.EducationComponent, View_EducationComponent_Host_0, {}, {}, []);
export { EducationComponentNgFactory as EducationComponentNgFactory };
