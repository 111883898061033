<div class="profile-page">
    <div class="wrapper">
      <div class="page-header page-header-small" filter-color="green">
        <div class="page-header-image" data-parallax="true" style="background-image: url('assets/images/intro.jpg');">
        </div>
        <div class="container">
          <div class="content-center">
            <div class="cc-profile-image">
              <a href="#">
                <img src="assets/images/yacine_profile.jpg" alt="Image" />
              </a>
            </div>
            <div class="h2 title">Yacine El Youssoufi</div>
            <p class="category text-white">Full Stack Web Developer</p>
            <a class="btn btn-primary smooth-scroll mr-2" href="#contact" data-aos="zoom-in"
              data-aos-anchor="data-aos-anchor">Contact Me</a>
            <a class="btn btn-primary"
              href="https://drive.google.com/file/d/1ShZSQSZhiM7_cgGhNT51LhVEFmxUeckT/view?usp=sharing" target="_blank"
              data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Download CV</a>
          </div>
        </div>
        <div class="section">
          <div class="container">
            <div class="button-container">
           
 
              <a class="btn btn-default btn-round btn-lg btn-icon" href="https://www.linkedin.com/in/yacine-el-youssoufi-3a202887/"
                target="_blank" rel="tooltip" title="Follow me on Linkedin">
                <i class="fa fa-linkedin"></i>
              </a>
              <a class="btn btn-default btn-round btn-lg btn-icon" href="https://github.com/yacsnipe"
                target="_blank" rel="tooltip" title="Follow me on Github">
                <i class="fa fa-github"></i>
              </a>
     
    
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
